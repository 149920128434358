<template>
  <label
    for="simple-language"
    class="flex items-center space-x-2 rounded-full border-2 p-4 lg:py-0.5 lg:px-2"
    :class="isSimpleLanguageActive ? 'border-blue-500' : 'border-gray-600'"
  >
    <span
      class="text-2xs font-medium leading-none"
      :class="isSimpleLanguageActive ? 'text-blue-500' : 'text-gray-600'"
      >Leichte Sprache</span
    >
    <input
      id="simple-language"
      v-model="isSimpleLanguageActive"
      type="checkbox"
      class="absolute h-full w-full cursor-pointer opacity-0"
      @click="toggleSimpleLang"
    />
    <IconCheckmark
      class="w-4"
      :class="
        isSimpleLanguageActive
          ? 'text-blue-500 stroke-white'
          : 'text-gray-600 stroke-white'
      "
    />
  </label>
</template>

<script lang="ts" setup>
import { store } from '/store.js'

const router = useRouter()
const isSimpleLanguageActive = ref(false)

function toggleSimpleLang() {
  isSimpleLanguageActive.value = !isSimpleLanguageActive.value
  store.isSimpleLanguage = isSimpleLanguageActive.value

  const moveToRoute = store.alternates.find((page) => {
    if (isSimpleLanguageActive.value) {
      return page.locale === 'de-plain'
    } else {
      return page.locale === 'default'
    }
  })

  if (moveToRoute?.url) {
    const path = isSimpleLanguageActive.value
      ? moveToRoute.url.replace('/de', '/plain')
      : moveToRoute.url.replace('/de', '')

    router.push({
      path: path,
    })
  }
}
onMounted(() => {
  if (process.server) return
  isSimpleLanguageActive.value = store.isSimpleLanguage
})
</script>
